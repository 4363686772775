import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import format from 'date-fns/format';
import { nb } from 'date-fns/locale'

import { useGetDataQualityChecksQuery, useRunDataQualityPerformedCheckQuery } from 'api/apiSlice';
import CheckResultDisplay from 'components/HealthCheck/CheckResultDisplay';

const RunCheck = () => {
  const [selectedCheck, setSelectedCheck] = useState('');
  const [checkDescription, setCheckDescription] = useState('');
  const [runResult, setRunResult] = useState(null);
  const [triggerCheck, setTriggerCheck] = useState(false);
  const [parameters, setParameters] = useState({});
  const [parameterValues, setParameterValues] = useState({});

  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() - 1);
  const [selectedDate, setSelectedDate] = useState(defaultDate);

  const { data: checks = [], isLoading, error } = useGetDataQualityChecksQuery();

  const { data: checkResult, isFetching: isRunning } = useRunDataQualityPerformedCheckQuery({
    id: selectedCheck,
    endDate: format(selectedDate, 'yyyy-MM-dd'),
    parameters: parameterValues,
  }, {
    skip: !triggerCheck,
  });

  useEffect(() => {
    if (checkResult) {
      setRunResult({
        check_name: checkResult.check_name,
        check_passed: checkResult.check_passed,
        result_details: checkResult.json_data,
        raw_result_details: checkResult.raw_data,
        last_updated: checkResult.last_updated,
      });
      setTriggerCheck(false);
    }
  }, [checkResult]);
  const handleCheckSelect = (event) => {
    const selectedCheckId = event.target.value;
    setSelectedCheck(selectedCheckId);
  
    const selectedCheckData = checks.find(check => check.id === selectedCheckId);
    if (selectedCheckData) {
      setCheckDescription(selectedCheckData.description || "No description available.");
      setParameters(selectedCheckData.parameters || {});
  
      // Initialize all parameter values with their defaults
      const initialParameterValues = {};
      Object.keys(selectedCheckData.parameters).forEach(paramName => {
        const paramMeta = selectedCheckData.parameters[paramName];
        if (paramMeta.type === 'date' && paramMeta.default) {
          initialParameterValues[paramName] = new Date(paramMeta.default);
        } else {
          initialParameterValues[paramName] = paramMeta.default !== undefined ? paramMeta.default : '';
        }
      });
      setParameterValues(initialParameterValues);
    }
  };
  const handleParameterChange = (name, value) => {
    let formattedValue;
  
    if (value instanceof Date) {
      // Ensure it's a valid date before formatting
      formattedValue = isNaN(value.getTime()) ? '' : format(value, 'yyyy-MM-dd');
    } else {
      // For non-date parameters, retain raw input for now
      formattedValue = value;
    }
  
    setParameterValues((prevValues) => ({
      ...prevValues,
      [name]: formattedValue,
    }));
  };
  

  const runCheck = () => {
    if (!selectedCheck || !selectedDate) return;

    // Reset the showRawData state in CheckResultDisplay
    setRunResult(null); // Reset the result when running a new check

    setTriggerCheck(true);
  };

  const renderParameterInput = (paramName, paramMeta) => {
    switch (paramMeta.type) {
      case 'date': {
        const dateValue = parameterValues[paramName]
          ? new Date(parameterValues[paramName])
          : null;
    
        return (
          <DatePicker
            label={paramMeta.label || paramName}
            value={dateValue}
            onChange={(newValue) => handleParameterChange(paramName, newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        );
      }
      case 'float':
        return (
          <TextField
            label={paramMeta.label || paramName}
            type="text" // Change to "text" to allow intermediate values
            value={parameterValues[paramName] || ''}
            onChange={(e) => handleParameterChange(paramName, e.target.value)}
            onBlur={(e) => handleParameterChange(paramName, parseFloat(e.target.value) || 0)} // Ensure final value is a valid number
            inputProps={{ inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' }} // Hint for numeric/decimal input
            fullWidth
          />
        );
      
      case 'string':
        return (
          <TextField
            label={paramMeta.label || paramName}
            value={parameterValues[paramName] || ''}
            onChange={(e) => handleParameterChange(paramName, e.target.value)}
            fullWidth
          />
        );
      default:
        return null;
    }
  };
  

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nb}>
      <Box sx={{ height: "83vh", width: "100%", p: 2 }}>
        <h1>Run Data Quality Check</h1>

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={6}>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={6}>
                <TextField
                  select
                  label="Select Data Quality Check"
                  value={selectedCheck}
                  onChange={handleCheckSelect}
                  fullWidth
                  margin="none"
                  disabled={isLoading || error}
                  sx={{ mb: 2 }}
                >
                  {!isLoading && !error && checks.length > 0 ? (
                    checks.map((check) => (
                      <MenuItem key={check.id} value={check.id}>
                        {check.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled value="">
                      {isLoading ? "Loading..." : "No checks available"}
                    </MenuItem>
                  )}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  label="Select Date"
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
            </Grid>

            {Object.keys(parameters).length > 0 && (
              <Box sx={{ mt: 2, maxWidth: 500 }}>
                <h3>Check Parameters</h3>
                {Object.keys(parameters).map((paramName) => (
                  <Box sx={{ mb: 2 }} key={paramName}>
                    {renderParameterInput(paramName, parameters[paramName])}
                  </Box>
                ))}
              </Box>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={runCheck}
              disabled={!selectedCheck || !selectedDate || isRunning}
              sx={{ mt: 2 }}
            >
              {isRunning ? 'Running...' : 'Run Check'}
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Box
              sx={{
                p: 2,
                border: '1px solid grey',
                borderRadius: '4px',
                backgroundColor: '#f5f5f5',
                height: '100%',
                minHeight: '300px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h3>Check Description</h3>
              <Box sx={{ flexGrow: 1 }}>
                <p>{checkDescription || "Select a check to view its description."}</p>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Reusable CheckResultDisplay component */}
        <CheckResultDisplay
          runResult={runResult}
          checks={checks}
          selectedCheck={selectedCheck}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default RunCheck;
