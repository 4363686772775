import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import { formatValues } from "../../../utils/functions";
import { useExtendedTranslation } from "hooks/useExtendedTranslation"; // Adjust path if needed

export default function CustomCard({ data }) {
  const { t } = useExtendedTranslation();
  console.log("CustomCard data:", data); // Debugging

  return (
    <Box style={{ marginTop: "50px" }}>
      {data && typeof data === "object"
        ? Object.entries(data).map(([key, item], index) => {
            const label = t(item.label); // ✅ this is all you need

            return (
              <Card
                key={index}
                sx={{
                  marginTop: "20px",
                  backgroundColor: (theme) => theme.palette.primary.main,
                  overflow: "visible",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      color: (theme) =>
                        theme.palette.getContrastText(theme.palette.primary.main),
                      textAlign: "center",
                    }}
                  >
                    {label}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      color: (theme) =>
                        theme.palette.getContrastText(theme.palette.primary.main),
                      textAlign: "center",
                    }}
                  >
                    {typeof item?.value === "object"
                      ? JSON.stringify(item.value)
                      : item?.value !== 0
                      ? formatValues(
                          item?.value,
                          -1 < item?.value
                            ? item?.value > 1
                              ? "millions"
                              : "percent"
                            : "millions"
                        )
                      : item?.value}
                  </Typography>
                </CardContent>
              </Card>
            );
          })
        : "No Data Available"}
    </Box>
  );
}
