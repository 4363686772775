import { useTranslation as useOriginalTranslation } from "react-i18next";

/**
 * Custom hook to extend translation functionality.
 */
export const useExtendedTranslation = () => {
  const { t: originalT, i18n } = useOriginalTranslation();

  /**
   * Recursively extracts primitive values from nested translation placeholders.
   * Example:
   * {
   *   period: { key: 'period.since_start', value: { startDate: '01.01.2024' } },
   *   currency: 'NOK'
   * }
   * becomes:
   * {
   *   period: 'since 01.01.2024',
   *   currency: 'NOK'
   * }
   */
  const translateRecursive = (value) => {
    if (typeof value !== "object" || value === null) {
      return value;
    }
  
    if (value.key) {
      const inner = translateRecursive(value.value || {});
      return originalT(value.key, inner);
    }
  
    const result = {};
    for (const [k, v] of Object.entries(value)) {
      result[k] = translateRecursive(v);
    }
    return result;
  };
  

  /**
   * Main translation function that handles raw keys or structured { key, value } objects.
   */
  const t = (keyOrObject, options = {}, isChartTitle = false) => {
    if (typeof keyOrObject === "string") {
      const key = isChartTitle && !keyOrObject.startsWith("plotly.charts.titles.")
        ? `plotly.charts.titles.${keyOrObject}`
        : keyOrObject;

      const translated = originalT(key, options);
      return translated === key ? keyOrObject : translated;
    }

    if (typeof keyOrObject === "object" && keyOrObject.key) {
      const interpolated = translateRecursive(keyOrObject.value || keyOrObject.values || {});
      return originalT(keyOrObject.key, { ...options, ...interpolated });
    }

    return "";
  };

  return { t, i18n };
};
