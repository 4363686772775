import React from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Fade,
  Toolbar,
  Autocomplete,
  MenuItem,
  TextField,
} from "@mui/material";
import RTKQueryWrapper from "components/DataFetchWrapper/RTKQueryWrapper";
import { useTranslation } from "react-i18next";
import { useGetAnalyticsOptionsQuery } from "api/apiSlice";
import { PerformanceTable } from "./PerformanceTable";
const TimeSeries = () => {
  const startDate = useSelector((state) => state.user.customStartDate);
  const endDate = useSelector((state) => state.user.customEndDate);
  const [grouping, setGrouping] = React.useState(null);
  const [groupCode, setGroupCode] = React.useState(null);
  const { t } = useTranslation();
  const performanceTableGroupingOptions = useGetAnalyticsOptionsQuery(
    {
      startDate: startDate,
      endDate: endDate,
    },
    { skip: !endDate || !startDate }
  );

  const handleGroupChange = (event, value) => {
    setGrouping(value);
    setGroupCode(null);
  };

  const handleGroupCodeChange = (event, value) => {
    setGroupCode(value);
  };
  React.useEffect(() => {
    if (performanceTableGroupingOptions.data) {
      setGrouping(performanceTableGroupingOptions.data[1].defaultValue);
      if (
        performanceTableGroupingOptions.data[0].isVisibleTrue.includes(
          performanceTableGroupingOptions.data[1].defaultValue.code
        )
      ) {
        setGroupCode(performanceTableGroupingOptions.data[0].defaultValue);
      }
    }
  }, [performanceTableGroupingOptions.data]);
  return (
    <Grid container p={2}>
      <Grid item xs={12}>
        <RTKQueryWrapper query={performanceTableGroupingOptions}>
          {(data) => {
            return (
              <Toolbar sx={{ gap: 1 }}>
                <Autocomplete
                  sx={{ width: "200px" }}
                  value={grouping}
                  size={"small"}
                  options={data[1].options}
                  getOptionLabel={(option) =>
                    t(`inputs.options.${option.label}`, {
                      defaultValue: option.label,
                    })
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  }
                  defaultValue={data[1].defaultValue}
                  freeSolo={false}
                  openOnFocus
                  renderOption={(props, option) => {
                    return (
                      <MenuItem {...props} key={Math.random() + option.label}>
                        {t(`inputs.options.${option.label}`, {
                          defaultValue: option.label,
                        })}
                      </MenuItem>
                    );
                  }}
                  disabled={undefined}
                  onChange={handleGroupChange}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={t(`inputs.labels.grouping`)}
                        required
                        fullWidth
                        variant="outlined"
                      ></TextField>
                    );
                  }}
                ></Autocomplete>
                <Autocomplete
                  sx={{ width: "400px" }}
                  value={groupCode}
                  size={"small"}
                  options={data[0].options}
                  disabled={!data[0].isVisibleTrue.includes(grouping?.code)}
                  getOptionLabel={(option) =>
                    t(`inputs.options.${option.label}`, {
                      defaultValue: option.label,
                    })
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  }
                  defaultValue={data[0].defaultValue}
                  freeSolo={false}
                  openOnFocus
                  renderOption={(props, option) => {
                    return (
                      <MenuItem {...props} key={option.label}>
                        {t(`inputs.options.${option.label}`, {
                          defaultValue: option.label,
                        })}
                      </MenuItem>
                    );
                  }}
                  onChange={handleGroupCodeChange}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={t(`inputs.labels.allocationCode`)}
                        fullWidth
                        variant="outlined"
                      ></TextField>
                    );
                  }}
                ></Autocomplete>
              </Toolbar>
            );
          }}
        </RTKQueryWrapper>
      </Grid>

      <Grid item xs={12} md={12} xl={12}>
        <PerformanceTable
          grouping={grouping?.code}
          groupCode={groupCode?.value}
          startDate={startDate}
          endDate={endDate}
        />
      </Grid>
    </Grid>
  );
};

export default TimeSeries;
