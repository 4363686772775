import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { lazy, Suspense } from "react";
import { PrivateRoute } from "./PrivateRoute";
import Error from "../components/Error";

import MainLayout from "layouts/MainLayout";
import AttributionNode from "containers/pages/attribution/AttributionNode";
import ExternalMandatesLayout from "layouts/ExternalMandatesLayout";
import MyHoldingsLayout from "layouts/MyHoldingsLayout";
import PerformancePageLayout from "layouts/PerformancePageLayout";
import { InvestorRoutes } from "./InvestorRoutes";
import { ConfigurableCustomRoutes } from "./ConfigurableCustomRoutes";
import { CurrencyOverview } from "containers/pages/currencies/CurrencyOverview";
import { FXContracts } from "containers/pages/currencies/FXContracts";
import ThemeEditor from "containers/pages/user/ThemeEditor";
import Allocations from "containers/pages/positions/Allocations";
import { default as BankCurrencies } from "containers/pages/bank/Currencies";
import Companies from "containers/pages/bank/Companies";
import LoansTable from "containers/pages/bank/LoansTable";
import AccountsTable from "containers/pages/bank/AccountsTable";
import LoginLayout from "layouts/LoginLayout";
import Login from "containers/pages/auth/Login";
import ResetPassword from "containers/pages/auth/ResetPassword";
import ResetPasswordConfirm from "containers/pages/auth/ResetPasswordConfirm";
import { Document } from "containers/pages/documentProcessing/Document";

import { DocumentList } from "containers/pages/documentProcessing/DocumentList";
import HealthCheckLayout from "layouts/HealthCheckLayout";
import TWRCheck from "containers/pages/fa_doctor/TWRCheck";
import RunCheck from "containers/pages/fa_doctor/RunCheck";
import Subscriptions from "containers/pages/fa_doctor/Subscriptions"; 
import CheckResult from "containers/pages/fa_doctor/CheckResult"; 
import UserManagement from "containers/pages/userManagement";



const QandA = lazy(() => import("../containers/pages/quanda/QandA"));
const PositionsLayout = lazy(() => import("layouts/PositionsLayout"));
const CustomPagesLayout = lazy(() => import("layouts/CustomPagesLayout"));
const BankLayout = lazy(() => import("layouts/BankLayout"));

const UserMenu = lazy(() => import("../containers/pages/user/UserMenu"));
const NumberFormatsEdit = lazy(() =>
  import("../containers/pages/user/NumberFormatsEdit")
);
const Settings = lazy(() => import("../containers/pages/user/Settings"));
const ModularContainer = lazy(() =>
  import("../containers/modularContainer/ModularContainer")
);
const OrgChart = lazy(() =>
  import("../containers/pages/organization_overview/OrgChart")
);
const CompanyDetails = lazy(() =>
  import("../containers/pages/organization_overview/CompanyDetails")
);
const Test = lazy(() => import("../containers/pages/test/Test"));
const Liquidity = lazy(() => import("../containers/pages/liquidity/Liquidity"));
const DirectInvestmentsTable = lazy(() =>
  import("../containers/pages/direct_investments/DirectInvestments")
);

const UserProfile = lazy(() => import("../containers/pages/user/UserProfile"));

const ChangePassword = lazy(() =>
  import("../containers/pages/user/ChangePassword")
);
const Dashboard = lazy(() => import("../containers/pages/dashboard/Dashboard"));
const Attribution = lazy(() =>
  import("containers/pages/attribution/Attribution")
);
const CPage = lazy(() => import("../containers/customPages/cPages001/index"));
const CPageTwo = lazy(() =>
  import("../containers/customPages/cPages002/index")
);
const CPageThree = lazy(() =>
  import("../containers/customPages/cPages003/index")
);
const CPageFour = lazy(() =>
  import("../containers/customPages/cPages004/index")
);
const ConfigurableCustomPage = lazy(() =>
  import("../containers/customPages/configurableCustomPage/index")
);
const Currencies = lazy(() =>
  import("../containers/pages/currencies/Currencies")
);
const DocumentArchive = lazy(() =>
  import("containers/pages/documentArchive/DocumentArchive")
);
const DocumentProcessing = lazy(() =>
  import("../containers/pages/documentProcessing/DocumentProcessing")
);
const DirectInvestments = lazy(() =>
  import("../containers/pages/direct_investments/DirectInvestments")
);
const ExternalMandates = lazy(() =>
  import("containers/pages/externalmandates/ExternalMandates")
);
const ExcelDownloads = lazy(() =>
  import("containers/pages/excelDownloads/ExcelDownloads")
);
const MyHoldings = lazy(() =>
  import("../containers/pages/myHoldings/MyHoldings")
);
const OrgOverview = lazy(() =>
  import("containers/pages/organization_overview/OrgOverview")
);
const Positions = lazy(() => import("containers/pages/positions/Positions"));
const HealthCheck = lazy(() => import("containers/pages/fa_doctor/HealthCheck"))
const XrayPage = lazy(() => import("../containers/pages/xray/XrayPage"));
const Transactions = lazy(() =>
  import("containers/pages/transactions/Transactions")
);
const SettingsLayout = lazy(() => import("layouts/SettingsLayout"));
const Limits = lazy(() => import("../containers/pages/limits/Limits"));
const PE = lazy(() => import("../containers/pages/private_equity/PE"));
const DocumentProcessingNew = lazy(() =>
  import("../containers/pages/documentProcessing/DocumentProcessingNew")
);

const componentMap = {
  ConfigurablePageDashboard: lazy(() => import("containers/customPages/configurableCustomPage/ConfigurablePageDashboard")),
  ConfigurablePageFundPerformance: lazy(() => import("containers/customPages/configurableCustomPage/ConfigurablePageFundPerformance")),
  ConfigurablePageSecurityPerformance: lazy(() => import("containers/customPages/configurableCustomPage/ConfigurablePageSecurityPerformance")),
  ConfigurablePageFundAllocation: lazy(() => import("containers/customPages/configurableCustomPage/ConfigurablePageFundAllocation")),
  ConfigurablePageContributors: lazy(() => import("containers/customPages/configurableCustomPage/ConfigurablePageContributors")),
  ConfigurablePageNetCashFlow: lazy(() => import("containers/customPages/configurableCustomPage/ConfigurablePageNetCashFlow")),
};


const ApplicationRoutes = () => {
    // Get pages from Redux state
    const pages = useSelector((state) => state.user.settings.pages);

    const customRoutes = pages
    .filter((page) => page.custom)
    .map((page) => ({
      path: page.path, // ✅ No "app/" prefix
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <ConfigurableCustomPage config={page.config} pagePath={page.path} />
        </Suspense>
      ),
      children: page.config?.tabs?.map((tab) => ({
        path: tab.key,
        element: React.createElement(
          componentMap[tab.component] || (() => <div>Component Not Found</div>)
        ),
      })),
    }));
  

    console.log("customRoutes;", customRoutes)
  
  

  const APPLICATION_PAGE_ROUTES = [
    {
      path: "",
      errorElement: <Error />,
      children: [
        {
          path: "app",
          element: (
            <PrivateRoute>
              <MainLayout />
            </PrivateRoute>
          ),
          errorElement: <Error />,
          children: [
            {
              path: "user",
              element: <SettingsLayout />,
              children: [
                {
                  path: "settings",
                  element: <Settings />,
                },
                {
                  path: "themeeditor",
                  element: <ThemeEditor />,
                },
                {
                  path: "numberformats",
                  element: <NumberFormatsEdit></NumberFormatsEdit>,
                },
                {
                  path: "profile",
                  element: <UserProfile></UserProfile>,
                },
                {
                  path: "changepassword",
                  element: <ChangePassword></ChangePassword>,
                },
                {
                  index: true,
                  element: <UserMenu></UserMenu>,
                },
              ],
            },
            {
              path: "qanda",
              element: <QandA />,
            },

            {
              path: "dashboard",
              element: <Dashboard />,
            },
            {
              path: "pe",
              element: <PE />,
            },

            {
              path: "externalmandates",
              element: <ExternalMandatesLayout />,
              children: [
                {
                  path: ":mandateId",
                  element: <ExternalMandates />,
                },
              ],
            },
            {
              path: "custom/*",
              element: <CustomPagesLayout />,
            },
            {
              path: "cpage",
              element: <CPage />,
            },
            {
              path: "cpagetwo",
              element: <CPageTwo />,
            },
            {
              path: "cpagefour",
              element: <CPageFour />,
            },
            // {
            //   path: "configureable-custom-page",
            //   element: <ConfigurableCustomPage />,
            //   children: [...ConfigurableCustomRoutes],
            // },
            // {
            //   path: "another-custom-page",
            //   element: <ConfigurableCustomPage />,
            //   children: [...ConfigurableCustomRoutes],
            // },
            {
              path: "investorportal",
              element: <CPageThree />,
              children: [...InvestorRoutes],
            },
            {
              path: "myholdings",
              element: <MyHoldingsLayout />,
              children: [
                {
                  index: true,
                  path: ":portfolioId",
                  element: <MyHoldings />,
                },
              ],
            },
            {
              path: "organization",
              element: <OrgOverview />,
              children: [
                {
                  index: true,
                  element: <OrgChart />,
                },
                {
                  path: ":companyId",
                  element: <CompanyDetails />,
                },
              ],
            },
            {
              path: "directinvestments",
              element: <DirectInvestments />,
              children: [
                {
                  index: true,
                  element: <DirectInvestmentsTable />,
                },
                {
                  path: ":tabId",
                  element: <DirectInvestmentsTable />,
                },
              ],
            },

            {
              path: "positions",
              element: <PositionsLayout />, // Use the updated PositionsLayout component
              children: [
                {
                  path: "allocations",
                  element: <Allocations />,  // Explicitly define the Allocations route
                },
                {
                  path: "holdings",
                  element: <Positions />, // Explicitly define the Holdings route
                },
                {
                  index: true,
                  element: <Positions />, // This will be redirected by the Navigate component in PositionsLayout
                },
              ],
            },
            
            // {
            //   path: "positions",
            //   element: <PositionsLayout />,
            //   children: [
            //     {
            //       path: "allocations",
            //       element: <Allocations />,
            //     },
            //     {
            //       path: "holdings",
            //       element: <Positions />,
            //     },
            //     {
            //       index: true,
            //       element: <Positions />,
            //     },
            //   ],
            // },
            {
              path: "fa_doctor/*",
              element: <HealthCheckLayout />,
              children: [
                {
                  path: "dashboard",
                  element: <HealthCheck />,
                },
                {
                  path: "run_check",
                  element: <RunCheck />,
                },
                {
                  path: "timeseries",
                  element: <TWRCheck />,
                },
                {
                  path: "subscriptions",
                  element: <Subscriptions />,
                },
                {
                  path: "check_result/:id",  
                  element: <CheckResult />,  
                },
                {
                  index: true,
                  element: <HealthCheck />,
                },
              ],
            },
            {
              path: "fa_doctor/*",
              element: <HealthCheck />,
            },
            {
              path: "performance/*",
              element: <PerformancePageLayout />, //dynamic children is defined in the layout
            },
            {
              path: "xray",
              element: <XrayPage />,
            },
            {
              path: "currencies",
              element: <Currencies />,
              children: [
                {
                  index: true,
                  element: <CurrencyOverview />,
                },
                {
                  path: "overview",
                  element: <CurrencyOverview />,
                },
                {
                  path: "fxcontracts",
                  element: <FXContracts />,
                },
              ],
            },
            {
              path: "attribution",
              element: <Attribution />,
              children: [
                {
                  index: true,
                  path: ":portfolioId",
                  element: <AttributionNode />,
                },
              ],
            },
            {
              path: "bank",
              element: <BankLayout />,
              children: [
                {
                  path: "currency",
                  element: <BankCurrencies />,
                },
                {
                  path: "loans",
                  element: <LoansTable />,
                },
                {
                  path: "accounts",
                  element: <AccountsTable />,
                },
                {
                  path: "companies",
                  index: true,
                  element: <Companies />,
                },
                {
                  path: "",
                  element: <Companies />,
                },
              ],
            },
            {
              path: "transactions",
              element: <Transactions />,
            },
            {
              path: "limits",
              element: <Limits />,
            },
            {
              path: "exceldownloads",
              element: <ExcelDownloads />,
            },
            {
              path: "document-processing",
              element: <DocumentProcessing />,
            },
            {
              path: "document-processing-new",
              element: <DocumentProcessingNew />,
              children: [
                {
                  path: ":documentId",
                  element: <Document />,
                },
                {
                  index: true,
                  path: "",
                  element: <DocumentList />,
                },
              ],
            },
            {
              path: "documentarchive",
              element: <DocumentArchive />,
            },
            {
              path: "liquidity",
              element: <Liquidity />,
            },
            {
              path: "custom-dashboard",
              element: <ModularContainer />,
            },
            {
              path: "test",
              element: <Test />,
            },
            {
              path: "userManagement",
              element: <UserManagement />,
            },
            ...customRoutes,
          ],
        },
      ],
    },
    {
      path: "login",
      element: <LoginLayout />,
      children: [
        {
          index: true,
          element: <Login />,
        },
        {
          path: "reset",
          element: <ResetPassword />,
        },
        {
          path: "resetConfirm/:uid/:token",
          element: <ResetPasswordConfirm />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to={"/login"} />,
    },
  ];
  console.log("APPLICATION_PAGE_ROUTES:", APPLICATION_PAGE_ROUTES)

  return useRoutes(APPLICATION_PAGE_ROUTES);
};

export default ApplicationRoutes;
