import React , { useEffect }from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Fade, Grid, Card, CardContent, Box } from "@mui/material";
import RTKQueryWrapper from "components/DataFetchWrapper/RTKQueryWrapper";
import {
  useGetTimeSeriesDailyQuery,
  useGetTimeSeriesMonthlyQuery,
  useGetAeternumAnnualReturnQuery
} from "api/apiSlice";
import ChartOne from "../cPages002/ChartOne";

function CPageFundPerformance() {
  const startDate = useSelector((state) => state.user.presetStartDate);
  const endDate = useSelector((state) => state.user.endDate);
  const selectedClient = useSelector((state) => state.user.chosen_client.code);

  const timeSeriesMonthlyQuery = useGetTimeSeriesMonthlyQuery({
    startDate: startDate,
    endDate: endDate,
  });
  const timeSeriesDailyQuery = useGetTimeSeriesDailyQuery({
    startDate: startDate,
    endDate: endDate,
  });

  const aeternumAnnualReturnQuery = useGetAeternumAnnualReturnQuery({
    startDate: startDate,
    endDate: endDate,
  });

  useEffect(() => {
    if (aeternumAnnualReturnQuery.refetch) {
      aeternumAnnualReturnQuery.refetch(); // Trigger re-fetch when client changes
    }
  }, [selectedClient]); // Dependency array listens for client changes


  return (
    <Grid container spacing={2}>

      <Fade in={true} timeout={1000}>
        <Grid item xs={12} md={6} xl={6}>
          <Card sx={{ m: 2, minHeight: "100%" }}>
            <CardContent sx={{ p: 0, paddingBottom: "0 !important" }}>
              <RTKQueryWrapper query={timeSeriesMonthlyQuery}>
                {(data) => (
                  <ChartOne data={data} sx={{ height: "58vh" }} isPercentageData={true}></ChartOne>
                )}
              </RTKQueryWrapper>
            </CardContent>
          </Card>
        </Grid>
      </Fade>

      <Fade in={true} timeout={1000}>
        <Grid item xs={12} md={6} xl={6}>
          <Card sx={{ m: 2, minHeight: "100%" }}>
            <CardContent sx={{ p: 0, paddingBottom: "0 !important" }}>
              <RTKQueryWrapper query={timeSeriesDailyQuery}>
                {(data) => (
                  <ChartOne data={data} sx={{ height: "58vh" }} isPercentageData={true}></ChartOne>
                )}
              </RTKQueryWrapper>
            </CardContent>
          </Card>
        </Grid>
      </Fade>

      <Fade in={true} timeout={1000}>
        <Grid container spacing={2}>
          {/* Header */}
          <Grid item xs={12}>
            <Card sx={{ m: 2, minHeight: 50 }}>
              <CardContent>
                <RTKQueryWrapper query={aeternumAnnualReturnQuery}>
                  {(data) => {
                    if (!data || !Array.isArray(data.values)) {
                      return null; // Return null if data or values array is not available
                    }
                    const values = data.values;
                    return (
                      <>
                        {values.map((item) => {
                          if (!item) return null; // Skip null/undefined items
                          if (item.isHeader) {
                            // Render header box spanning full width
                            return (
                              <Box
                                key={item.id || Math.random()}
                                sx={{
                                  height: 35,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  p: 1,
                                  border: "1px solid",
                                  borderColor: "divider",
                                  borderRadius: 2,
                                  backgroundColor: item.color,
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  fontSize: "1.1rem",
                                  mb: 1, // Add spacing below the header
                                }}
                              >
                                {item.headerName}
                              </Box>
                            );
                          }
                          return null; // Skip non-header items in this loop
                        })}

                        {/* Row of Boxes */}
                        <Grid container spacing={2}>
                          {values.map((item) => {
                            if (!item) return null; // Skip null/undefined items
                            if (!item.isHeader) {
                              // Render regular boxes
                              return (
                                <Grid item xs={12 / (values.length - 1)} key={item.id || Math.random()}>
                                  <Box
                                    sx={{
                                      height: 35,
                                      p: 1,
                                      border: "1px solid",
                                      borderColor: "divider",
                                      borderRadius: 2,
                                      backgroundColor: item.color,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "0.9rem",
                                      textAlign: "center",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {`${item.headerName} ${item.value !== null && item.value !== undefined
                                        ? (item.value * 100).toFixed(item.decimals) + (item.format === "percent" ? "%" : "")
                                        : ""
                                      }`}
                                  </Box>
                                </Grid>
                              );
                            }
                            return null; // Skip header items in this loop
                          })}
                        </Grid>
                      </>
                    );
                  }}
                </RTKQueryWrapper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Fade>





    </Grid>
  );
}

export default CPageFundPerformance;
